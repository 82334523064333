// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App/App';
// import Home from './Home/Home'; // Ensure this import is correct
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const isFirstVisit = sessionStorage.getItem('isFirstVisit');

// // Suppress the ResizeObserver loop limit exceeded warning
// window.addEventListener('error', (e) => {
//   if (e.message === "ResizeObserver loop limit exceeded") {
//     e.stopImmediatePropagation();
//   }
// });

// if (!isFirstVisit) {
//   sessionStorage.setItem('isFirstVisit', 'false');
  
//   root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );

//   setTimeout(() => {
//     root.render(
//       <React.StrictMode>
//         <Home />
//       </React.StrictMode>
//     );
//   }, 5000);

// } else {
//   console.log('This is not the first visit. App will not be rendered.');
//   root.render(
//     <React.StrictMode>
//       <Home/>
//     </React.StrictMode>
//   );
// }

// reportWebVitals();


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App/App';
// import Home from './Home/Home'; // Ensure this import is correct
// import reportWebVitals from './reportWebVitals';
// import { initializeApp } from 'firebase/app';
// import { getDatabase, ref, set, get, update, child } from 'firebase/database';

// // Firebase configuration (replace with your Firebase project config)
// const firebaseConfig = {
//   apiKey: "AIzaSyAO1iYerqbX0E88e5GmMqYUpOxSZyQFIbY",
//   authDomain: "squarecomputers-c8d02.firebaseapp.com",
//   databaseURL: "https://squarecomputers-c8d02-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "squarecomputers-c8d02",
//   storageBucket: "squarecomputers-c8d02.appspot.com",
//   messagingSenderId: "928660043240",
//   appId: "1:928660043240:web:7168ad91c8a10534e1e05d",
//   measurementId: "G-48B92HCB8C"
// };

// // Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// const database = getDatabase(firebaseApp);

// const root = ReactDOM.createRoot(document.getElementById('root'));

// const isFirstVisit = sessionStorage.getItem('isFirstVisit');

// // Suppress the ResizeObserver loop limit exceeded warning
// window.addEventListener('error', (e) => {
//   if (e.message === "ResizeObserver loop limit exceeded") {
//     e.stopImmediatePropagation();
//   }
// });

// const detectDevice = () => {
//   const userAgent = navigator.userAgent.toLowerCase();
//   if (/mobile|android|iphone|ipad|ipod/.test(userAgent)) {
//     return "Mobile";
//   }
//   return "Computer";
// };

// const detectOS = () => {
//   const platform = navigator.platform.toLowerCase();
//   if (platform.includes('win')) return 'Windows';
//   if (platform.includes('mac')) return 'MacOS';
//   if (platform.includes('linux')) return 'Linux';
//   if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
//   if (/iphone|ipad|ipod/.test(navigator.userAgent.toLowerCase())) return 'iOS';
//   return 'Unknown';
// };

// const fetchCookiesAndSession = () => {
//   const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
//     const [key, value] = cookie.split('=');
//     acc[key] = value;
//     return acc;
//   }, {});

//   const sessionEmail = sessionStorage.getItem('email') || 'Not Available';
//   const sessionUsername = sessionStorage.getItem('username') || 'Not Available';

//   return {
//     email: cookies.email || sessionEmail || 'Not Available',
//     username: cookies.username || sessionUsername || 'Not Available'
//   };
// };

// const recordUserDetails = () => {
//   const device = detectDevice();
//   const os = detectOS();
//   const { email, username } = fetchCookiesAndSession();

//   fetch('https://api.ipify.org?format=json')
//     .then(response => response.json())
//     .then(data => {
//       const ipAddress = data.ip || 'Not Available';
//       const userDetailsRef = ref(database, 'user_details');

//       get(userDetailsRef)
//         .then(snapshot => {
//           if (snapshot.exists()) {
//             // Increment the Count and add new data
//             const existingData = snapshot.val();
//             const count = existingData.Count ? existingData.Count + 1 : 1;

//             update(userDetailsRef, {
//               Count: count,
//               Email: email,
//               Username: username,
//               IPAddress: ipAddress,
//               Device: device,
//               OS: os,
//               Timestamp: new Date().toISOString()
//             });
//           } else {
//             // Add the data if it doesn't exist
//             set(userDetailsRef, {
//               Count: 1,
//               Email: email,
//               Username: username,
//               IPAddress: ipAddress,
//               Device: device,
//               OS: os,
//               Timestamp: new Date().toISOString()
//             });
//           }
//         })
//         .catch(error => {
//           console.error('Error reading from Firebase:', error);
//         });
//     })
//     .catch(error => {
//       console.error('Error fetching IP address:', error);
//     });
// };

// if (!isFirstVisit) {
//   sessionStorage.setItem('isFirstVisit', 'false');

//   root.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   );

//   setTimeout(() => {
//     root.render(
//       <React.StrictMode>
//         <Home />
//       </React.StrictMode>
//     );
//     recordUserDetails();
//   }, 5000);

// } else {
//   console.log('This is not the first visit. App will not be rendered.');
//   root.render(
//     <React.StrictMode>
//       <Home />
//     </React.StrictMode>
//   );
//   recordUserDetails();
// }

// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import Home from './Home/Home';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get } from 'firebase/database';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAO1iYerqbX0E88e5GmMqYUpOxSZyQFIbY",
  authDomain: "squarecomputers-c8d02.firebaseapp.com",
  databaseURL: "https://squarecomputers-c8d02-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "squarecomputers-c8d02",
  storageBucket: "squarecomputers-c8d02.appspot.com",
  messagingSenderId: "928660043240",
  appId: "1:928660043240:web:7168ad91c8a10534e1e05d",
  measurementId: "G-48B92HCB8C"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const database = getDatabase(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById('root'));

const isFirstVisit = sessionStorage.getItem('isFirstVisit');

// Suppress the ResizeObserver loop limit exceeded warning
window.addEventListener('error', (e) => {
  if (e.message === "ResizeObserver loop limit exceeded") {
    e.stopImmediatePropagation();
  }
});

const detectDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile|android|iphone|ipad|ipod/.test(userAgent)) {
    return "Mobile";
  }
  return "Computer";
};

const detectOSAndVersion = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  let os = "Unknown";
  let osVersion = "Unknown";

  if (userAgent.includes('windows')) {
    os = "Windows";
    const match = userAgent.match(/windows nt ([\d.]+)/);
    osVersion = match ? match[1] : "Unknown";
  } else if (userAgent.includes('mac')) {
    os = "MacOS";
    const match = userAgent.match(/mac os x ([\d._]+)/);
    osVersion = match ? match[1].replace(/_/g, '.') : "Unknown";
  } else if (userAgent.includes('android')) {
    os = "Android";
    const match = userAgent.match(/android ([\d.]+)/);
    osVersion = match ? match[1] : "Unknown";
  } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
    os = "iOS";
    const match = userAgent.match(/os ([\d_]+)/);
    osVersion = match ? match[1].replace(/_/g, '.') : "Unknown";
  }

  return { os, osVersion };
};

const fetchCookiesAndSession = () => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = value;
    return acc;
  }, {});

  const sessionEmail = sessionStorage.getItem('email') || 'Not Available';
  const sessionUsername = sessionStorage.getItem('username') || 'Not Available';
  const phoneNumber = cookies.phone || sessionStorage.getItem('phone') || 'Not Available';

  return {
    email: cookies.email || sessionEmail,
    username: cookies.username || sessionUsername,
    phoneNumber
  };
};

const generateUserID = () => {
  return Math.floor(10000 + Math.random() * 90000); // Generates a 5-digit random number
};

const recordUserDetails = () => {
  const device = detectDevice();
  const { os, osVersion } = detectOSAndVersion();
  const { email, username, phoneNumber } = fetchCookiesAndSession();
  const userID = generateUserID();

  // Get the current browser time
  const browserTime = new Date().toLocaleString(); // Local time in the browser's timezone

  fetch('https://api.ipify.org?format=json')
    .then(response => response.json())
    .then(data => {
      const ipAddress = data.ip || 'Not Available';
      const userInfoRef = ref(database, `user_info/${userID}`);
      const userVisitRef = ref(database, 'user_visit');

      // Add user details to `user_info`
      set(userInfoRef, {
        User_ID: userID,
        Email: email,
        Username: username,
        PhoneNumber: phoneNumber,
        IPAddress: ipAddress,
        Device: device,
        OS: os,
        OSVersion: osVersion,
        Timestamp: new Date().toISOString(),
        BrowserTime: browserTime
      })
        .then(() => console.log('User info saved successfully:', userID))
        .catch(error => console.error('Error saving user info:', error));

      // Update user_visit collection
      get(userVisitRef)
        .then(snapshot => {
          const count = snapshot.exists() ? snapshot.val().Count + 1 : 1;
          set(userVisitRef, { Count: count })
            .then(() => console.log('User visit count updated:', count))
            .catch(error => console.error('Error updating user visit count:', error));
        })
        .catch(error => console.error('Error reading user visit count:', error));
    })
    .catch(error => {
      console.error('Error fetching IP address:', error);
    });
};

if (!isFirstVisit) {
  sessionStorage.setItem('isFirstVisit', 'false');

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );

  setTimeout(() => {
    root.render(
      <React.StrictMode>
        <Home />
      </React.StrictMode>
    );
    recordUserDetails();
  }, 5000);

} else {
  console.log('This is not the first visit. App will not be rendered.');
  root.render(
    <React.StrictMode>
      <Home />
    </React.StrictMode>
  );
  recordUserDetails();
}

reportWebVitals();

