import React, { useState, useEffect } from "react";
import "./ReviewsCarousel.css";
import { useSwipeable } from "react-swipeable";
import { useSpring, animated } from "react-spring";
import { useDrag } from "react-use-gesture";

const reviewsData = [
    
    {
        "name": "udesh purohit",
        "rating": "5 stars",
        "review": "“I recently bought a computer, and their service was excellent. Highly satisfied with the experience!”",
        "time": "2 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXhpAGYExpsYacRi4BO3O4qU7txZhyjO1D1VKN6HaQb6ZimGuns=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "madwesh bv",
        "rating": "5 stars",
        "review": "Best quality products with compitative price,\nand the good service provider.",
        "time": "2 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWB5hT3HK6cxdCce394T7VLkkxCNRtJEs0f-n6l-PIFyQouPUOKlQ=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "prajwal naik",
        "rating": "5 stars",
        "review": "I give a 5 ⭐ Happy to buy this laptop,\nLaptop is exalent condition Thank You.",
        "time": "2 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVFacH3M-sQn2qVu4BieEoEXtSVwl49gGR8dXSC1HACrJxXf0c=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nagaraj B M",
        "rating": "5 stars",
        "review": "reaally a good service provider, and quick response from shop owner..thank you",
        "time": "3 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjW9skI72Fq1LTffEq0nzr3C1fkavtAEN_J2J_fque5EhCrfJ6F2=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "D H E E R A J",
        "rating": "5 stars",
        "review": "Square Computers is simply fantastic! I recently had the pleasure of purchasing a system from them, and I couldn't be happier with my decision. From start to finish, my experience with Square Computers was exceptional. …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjW1IuA_xfVathsojzXjc_oovP37g4lt1VLDeq-UAgzX71KOOWQo=w36-h36-p-rp-mo-ba4-br100"
    },
    {
        "name": "Raghavendra nayak",
        "rating": "5 stars",
        "review": "Great Service with care,\nPlease visit to get Computer exercise and Laptops...",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXCN6AfogNmnQUyXkYFzBLh-eG6oC-MEXKXSJK91Aw2ttdTdwnd=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Shiva Ulthur",
        "rating": "5 stars",
        "review": "\"Exceptional laptop service – fast, reliable, and reasonably priced, ensuring your device runs smoothly with expert care and timely solutions.\"",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXdSB2v0apTcf0_XBQJu7AOc4nqDjq77l1mGf6j79qlvgQf7uM=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Abhilash Devadiga",
        "rating": "5 stars",
        "review": "I have got serviced my office laptop here and also I have purchased  one used laptop for personal use too.\nReliable, prompt, professional, high quality, reasonable, quick and technically competent. Will highly recommend this place.",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXTGwm4zaQUFIVK2bisuVHuXWi1kFzqlbAzjqElN_S2thUmt0aC=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Jaidev Kamath",
        "rating": "5 stars",
        "review": "I had laptop HP pavillion and Dell 560s desktop for repair, they did very good job of explaining the problem in front of me and repaired it within a couple of days.Thank you Square computers",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjU2y1EegjSvmVqtXsO9FSCnDDUgTyl5WMQoihtHsLdy1O4GyIE=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Chethan Poojary",
        "rating": "5 stars",
        "review": "One of the Best Laptop center in udupi.\nSquare computer will provide a good service to their customers. If you want to buy new or refurbished laptop or computer just visit square computer.",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUqPw9KetxR_B3hbtg5-6YxJrE0_iLDzbddnaMb6WkSzzhIVucB=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Giridhar Prabhu",
        "rating": "5 stars",
        "review": "Best Customer service and Best condition laptops available for good price range.",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUnGt467Vij3WgC2iCkNqW0KutO2hhyWD3Im6C_fhEj7B9QlAs=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sanketh S Poojary",
        "rating": "5 stars",
        "review": "Bought a laptop for personal use with affordable price. Very humble owner. Highly recommend this place to buy Refurbished laptops.",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWvxHdUF_kXCHxgkr279lRotAo1mEBUl95JLcniCsbg1v7NoRUA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Abhijith",
        "rating": "5 stars",
        "review": "Genuine products and fast service.",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWDP8DHZGgoJ3--Pbl8nvpHTQWlkxzluIyjRBqdPSn-Juo9X3tx=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Lohith G B",
        "rating": "5 stars",
        "review": "Best computer 🖥️💻 laptop sales and service center in saligrama... Customer friendly person... …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWevTKK6GT6wqN347DTBkaXxFpE05rdZpRZpJZ5UH4DXvFZBhZO=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "melwin dsilva",
        "rating": "5 stars",
        "review": "Trusted sale and service offered by Square Computers",
        "time": "3 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocLOdyC41NHtZp_eZ_sF2ggx9Zsuu9rU-tnFrlRzhR38196nLA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Bhalaram Bhalaram",
        "rating": "5 stars",
        "review": "Good service...i upgraded my laptop SSD here,quick response and amazing work by owner, thanks 👍 …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJJ17CRQxWRREo8goYFI76G3MZFCNfzAH4o_qlaF_qKchzRcA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Priya Raghu",
        "rating": "5 stars",
        "review": "Genuine person,Refurbished laptops are best collection, good service also, highly recommended this place for purchase Refurbished laptop...",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjV5tiTM2z_uXndneHd6D-A6dpwYa_rLLKhzNQ4KCTUgwzxhseMk=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Lohith Poojary",
        "rating": "5 stars",
        "review": "I was really searching for good computers.I went to several shops and their quality was really bad.But later I found square computers recommend by my brother.And really the quality is very good.Thankyou soo much",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVEeKB_wTN6JHHfIc5hi1MeUVU8ycH6vZMo0ZRvHrfi8YLGxLQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sudeep K",
        "rating": "5 stars",
        "review": "The owner is knowledgeable and service is fast and reasonable. Good service at reasonable price.",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJHDehmUNkoX1W0JDmEvy8W5Mh9BHrmPtSaJoaBBcze6okHwp0=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "koushal herle",
        "rating": "5 stars",
        "review": "Bought two laptops from here, kind person and have a good customer service. Had a good experience 😃 …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUneNpFQPE9KGPHmlu9pFSHBkZ5glMOO5oD2RKWynuFBotAZ5Y=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Srikanth Srikanth",
        "rating": "5 stars",
        "review": "Best Quality and best price for refurbished laptops,owner is very humble and friendly...",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXl30y-O-74mlgonp00d0sBqMcwAKq9DbUcQT1ZhnkKHwwosAc=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Vikram Bhandarkar",
        "rating": "5 stars",
        "review": "Must visit for New Laptops, Laptop repairs and services. Thanks for on time Software Installation & RAM upgradation.",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWdsIciqRT0YFbVkqrrtlKenrVnBhpzArlVdt7C0LkV1R0Da4LC=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Prathviraj R Basrur",
        "rating": "5 stars",
        "review": "best place for buying Refurbished computers , Laptops\nHighly Recommended 👍🏻 …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVkk7BtLkhDCx4X5pKpOY_DArs8s_4fxZhEx6nmLr9n-zXS_qy5=w36-h36-p-rp-mo-ba4-br100"
    },
    {
        "name": "Padiyar Nandan",
        "rating": "5 stars",
        "review": "Best service with a reasonable price, owner is such a nice man✨️",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJnVSgN0fbeMkMwHwfdTSgf4411wzY-TH2yidLO6rEOj6fr=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "kailash",
        "rating": "5 stars",
        "review": "U get best laptops and pcs here",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIj_I8fN6ZfDYyCEHWcwqOVEsSS1OoyWMrkvudjszdBlZy2BMI=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Roopa Nagendra Devadiga",
        "rating": "5 stars",
        "review": "Good and innovative machines which also updated ....the person was too honest and experienced one.....reasonable price",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUVOM7pof__9_cj3vI3KOZxBYwv4iVOKnkWhKdZD31Z83DKmM0q=w36-h36-p-rp-mo-ba4-br100"
    },
    {
        "name": "Harish Hosavoklumane vaddarse",
        "rating": "5 stars",
        "review": "Excellent fast service",
        "time": "3 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXAePwoB3tGYhrznheadtSiVmGjvggbnlFSUb9Q8lJAujU-4ZE=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "NIKHIL KOTIYAN",
        "rating": "5 stars",
        "review": "Laptop valuable price..... Good service 👍 …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWOZ5ylgEePY1x4q65YrHaHqWGfx-k7IcC8c_lYnSNtwfbS7Dyl=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Sangeetha Poojari",
        "rating": "5 stars",
        "review": "Trustworthy, very good service at reasonable price",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWaUZoQss7OIvD0zz06xTaje7h7Fr3D6EzlLUzulR6JJ2BXnZLw=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Vinayak a",
        "rating": "5 stars",
        "review": "Best service with a reasonable price.... 👌🏻 …",
        "time": "11 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVA2G2BMs0uX0Um1GXsLz9FCxLzYW9E6pGzY6Yt9gdLXvPCbO0=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Avinash Shetty",
        "rating": "5 stars",
        "review": "Very good service... Customer friendly and trustworthy...",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWjwSImZZtNaqqGfCT1OIa78wA0y_d4hBPOebdya7MjYhCbPu4I=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Amith Thekkatte",
        "rating": "5 stars",
        "review": "Best service. We r happy",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVwgmohWKSad6-CS9nYMLQw-VufQwnu-QzrDfUDBnnMvzAZrnXu_Q=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Rajendra Acharya",
        "rating": "5 stars",
        "review": "Best service.. And good response for any queries...",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXxZbwFTG_nqPYrFCO_-re4zjOXsyhauCEaUwFLibFughHp_l47EA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "raghavendra kome",
        "rating": "5 stars",
        "review": "Very good service and quick response.",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjW7KIVd-u3xyOCiQt6Lt4tmlR8JfJNGYH6q4pDOBjMxmzLQlLhaWA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Prasad Karanth",
        "rating": "5 stars",
        "review": "Authorized Refub Laptop and Desktop seller....",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocI_B7-gF72Ss69lMTlqyU8ryVCk0x2UIa9ImqNJ6V7hLuh9vg=w36-h36-p-rp-mo-ba4-br100"
    },
    {
        "name": "Manaswi high",
        "rating": "5 stars",
        "review": "Very good service and reasonable pricing",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKZwUr0q7yWCQBuLZlPXLWVjTqtaqPtsHKOR2T1Pn5FDIulTq8=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "arun adiga",
        "rating": "5 stars",
        "review": "Good survice",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXwo1hIK60c-1EvnrF53R9WMDOl2z-poPSeLTBpUh0ohaXnwoTIPQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sunil Kumar",
        "rating": "5 stars",
        "review": "Good service and reasonable price",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJbAhDGzFEok2u03MTtNCzuJtPJHsBTy3bKrSNpD5gjOjcRow=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Shraddha JR",
        "rating": "5 stars",
        "review": "Really good, customer friendly",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWOocICl62HaX1eLCilhRot02kYAS1CA7qhrssaFy8d-BR_xjA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "vidya kanchan",
        "rating": "5 stars",
        "review": "Good service",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVjA83mHmHE7wHldAA02NqhGSk7fLg1x5zMCjE4QCdBYnsLeEnS=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "DINESH ACHAR",
        "rating": "5 stars",
        "review": "Happy with quick and effective services",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXIdNYDNt0zxwLOVxkmKoEhAUKIJP2fJqiY6pGg0f3VLYnzNzf5uQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nikhil Billava",
        "rating": "5 stars",
        "review": "Excellent and quick service..🥰 …",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjV-1J_UJAecYhukubyJycPyCKKXV3O6h_PrFXFjXkyNVIYILr4=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "ananthakrishna holla",
        "rating": "5 stars",
        "review": "Super. Good service",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUrkSmZUrK8sJNtAfUqooN7K1XUmLRl6EtPbXtmKzZOHPWzqt9v=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "lohith Kunder21",
        "rating": "5 stars",
        "review": "Very good services",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIyJTIxYhpJRMH2oHDoIHIu0lYW5jJWwysYc4ZX4RzEzaxzkg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Jagadisha Holla",
        "rating": "5 stars",
        "review": "Excellent",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIliRCRzPm86Go-jDmO3vjmNWYahmQ2itK4t6r_J5SDTgqlNQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "E TRAVELS",
        "rating": "5 stars",
        "review": "Excellent service",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUb9QTlg_dDvorgsoQ39hM4a8sEIHvLvy0786msdv2-h_evVdU=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Krishnaprasad Golekar",
        "rating": "5 stars",
        "review": "Very good service",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKg-moZrce2a56vAebq912kwN2HkmPYil3E0rpecXMSIaEdLg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Vageesh Holla VK",
        "rating": "5 stars",
        "review": "Good service",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWmUdkDDAnuw0bYSAbGDriXM1o7MACtx5tETeNX4Le9fTb_hYGh=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Ranjith achar Ranjith",
        "rating": "5 stars",
        "review": "-",
        "time": "a month ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocL60sFp0Qyj2SlPVcl2rS0pQYmoh9hQDuuXbKeltSxVZEULsg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "santhosh devadiga",
        "rating": "5 stars",
        "review": "-",
        "time": "a month ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIPqG8bdl3sDTo9XhJ73NmqlwibgztzrK6oid5h1OxV_O2oPQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Manoj Kumar",
        "rating": "5 stars",
        "review": "-",
        "time": "a month ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWD341fghY66rJkmQPCw_3l-8to_53wkEe7HdhJ9UqjJaaKm-ceXg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Chandra Achar",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocI4RkaHoKpuTXLSjylcZK_xjHllUhGGVIMgfAf__dZf_nFZ6Q=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Abhishek Poojari",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKeaguWRgGdE1b0EiV_SbDfzHusWXSmkc_iwsfNTJ6Qq97KtQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Suresh Padiyar",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKxI5V0XYo2JJzqORjYhAKi14pqmyNvQJE6pr0MEbKJ4VnILw=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Ajith Shetty",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJsIozku86AYRr1PR18ZlQubZiiBlpk-udj7rXaZSmpmYKyKQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Impana Sagar",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocL4sGQaYpWtVJ_3W-l_6sLi0koUjBNKs9MlyLKRbCmU5AnFhQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nikhilesh Devadiga",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXKgh006w2Vha-Npbx6ZGswKJ_0pml__cB4awWBkCxyQBndA1Pk=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nagendra kv",
        "rating": "5 stars",
        "review": "-",
        "time": "4 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUX0Zto6lPFk2-VFQQuRT6BxJGGkDhY1g3xOqR0eSY_895ammeL=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "sumankumar kumar",
        "rating": "5 stars",
        "review": "-",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWkTHczulPh_U9MV-T7h7njISVc1yFCDM1IpJkdxRy_hjW6By4b=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Prajwal Prajwal",
        "rating": "5 stars",
        "review": "-",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJf3FG2LM8xvSLOZHI0I4gnhoDPjuTGDy1xrCV-RLwP6DUuo8k=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sachinkumar M",
        "rating": "5 stars",
        "review": "-",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKM0renHVKGyJjVlcI1FqwrquAfjB8cE1T_eVmMUKFGFlz4VA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Mithun Kumar Mendon",
        "rating": "5 stars",
        "review": "-",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVX88FcV2T7WsfNMnkukmP5gGpX74qasag9iVf0s8lUJBCPuiQoGQ=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Rajendra Saligrama (Poojary)",
        "rating": "5 stars",
        "review": "-",
        "time": "5 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVFZFzGzK6jQSAhDdzKV5l71_KJbAiCQXSKQwogpc9fsB-QQEiEXg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "47 RAKSHITHA",
        "rating": "5 stars",
        "review": "-",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocLkvoc7fwRlnH6Gohk6Hp-Q9FlVfLsT3njF4L8_AXwbYsZL2g=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Manoj kulal",
        "rating": "5 stars",
        "review": "-",
        "time": "10 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocLxH0_q7x9wYh9l5TO7fPJgEDTIV_zX-7uvWadGrjDIqbhlqQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Vinayaka Studio",
        "rating": "5 stars",
        "review": "-",
        "time": "11 months ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjX9Mp0IFLUvAsy5bK-YGk8rSk4q_JPOXN5usiZnnModNXc6tG4=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Santhosh P",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIgQWJ_6PtEY_X55BMFG4Fuhj2zS3Iq0OkHzkId3GaJ8Pv9mQ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Anantha Padiyar",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUMjr5mHhHYhb2IYbsfX4ZVSzORzbJ7RNfZyZyJQBb71KGXffaz=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Jeevan U Acharya",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUi0SjzEJ7PaLOY-0Jforzjzpim-Dd1y__skIi0gr9dh_9QWZfD=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Gopala Kundar",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWnS4KOZFM7j8aJ-xyS_lliEqUJso6N2f4nq5kNIkG3RCoaxBR3=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Arjun Kunder",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVHfLIgt172Nbi8Vup_8q95Z-StwH9h4dM7judIQJyD5ArJES3i=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nagaraja Reddy",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIuivwPoaWiEU47KSnUy5ycyx_9H1uK5yndmkYhZghGNEwa3g=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Vishwas Bhat",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXIhRL65ua4XOTt6WdAKy09DO-ymetWmYd0fBT4t9uyu3Idfg3D1Q=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Rashmika Acharya",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXYID2tWHBcx5Lc8TV79bv4LL3WM8HRCFUH2fwa_dn0X-cWiGwK=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Dheeraj Dheeraj",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUz0G_sK1oebaEBSskfpgq0lzuAfNN1ezJGNzoFSi7s57DV_EU9=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "B S RAGHAVENDRA ACHARYA",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWFiZ4Add-c56S-A6PbUqZCr8EP5XRs4DXnTB1C2W9J38sA_yi0=w36-h36-p-rp-mo-ba4-br100"
    },
    {
        "name": "Praveen Bangera Kodi",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXoZjyM6VjGYeS2BTg2JV0it0L9CJtwuRgXmhmsfq4dfYxewKB05w=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Rakesh kokkarne Photography",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXRdXrGa4Fia-phE2a8pTwHuCsJX0CTci7-IrNuMzkNXiFwavkK=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Rakesh.R. Puthran",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJX6OvmbHRzc9bgCkUJSrXyEFZKb1Amwtn5LWy6Z_TjCUGNqg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "yashvanth puthran",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjU_QT5-UUP-V72uD5BROZNOUhm_3bYdbTbEtmGMJkjebapZQlg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sriraksha",
        "rating": "5 stars",
        "review": "-",
        "time": "a year ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUq-USAC2cCzMJrsvtmRxGIzk06ToWgQiOvNqhpO6VJGzyJZF0Z=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "ShriLaxmi Saligrama",
        "rating": "5 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUt4oq_uVJIIdfsjwUO7TbW3nKBTqRUTX4VLE2xrKcBFOb2cT69ww=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sukèsh Pèrampallí",
        "rating": "4 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJuZMfYLF9n_q178mgevrqmuvl0A2XA8J9BIqzBrVY8gGuhHe3W=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Pavan Kumar",
        "rating": "4 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocKkMfc8wRJj8Qq6Yr4kT__9lHK5cKOqdouUOo4GN8zSTi74jg=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Ayyappa Shenoy",
        "rating": "5 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXwHY58EPFEEddyWAKxJDRT39KRu89T0aKFuM2u7uY4QgWZbWIK=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Rhushi Hebbar",
        "rating": "5 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUQ23z6UxitBe8Q5flsJ0lPkeb0cNV5nSynaK5GcgS2GyaTLOVZ=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Kishore Kanchan",
        "rating": "5 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUOj7H1vlst1vm_fwKqlIXc3eTA44I45oeICv27xU0KEWBeLHsL=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Raaghavendra Acharya",
        "rating": "5 stars",
        "review": "-",
        "time": "2 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjV6SsnrQuyM6_-cCvcg5yte6ZRt0AqFm6crTbXRWmC-gwUVVQw=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sandesh Acharya",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWzei-a-6lq2YNrvABl5TPw8663Zv-7i4n7gIx2CUyBBYxgzv6IwA=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Mallikarjun Security solution",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWJDq6AGXw3-A1GhJUfg3O7vjuSbzmGcp-plQUbH-pWzzO3aJiJ_Q=w36-h36-p-rp-mo-ba2-br100"
    },
    {
        "name": "Harishholla V",
        "rating": "4 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjUPrxaon0EtwFGXlz18nRoc5-6-Yb2NCtdAvfumN8YmAPQtcDDs=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "SURESH POOJARY",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjVRTyG-sZ9A4WNtFyOTCmcCEturCQq2JYL_4AWyiQCKwI0G9VbE=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Sangeetha Holla",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjU0N0WvW7LWtcwBMcNFDoTXP-7mM8SkqlDF_0z9UZN_mNYSw1xD=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Nagaraja Naga",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXJGQpRA1YdOxPVMcNsWH2STG9Ek74LSQOQ_P1RX0di1MSzsPJ_=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "srinath acharya",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXTHDrImIhETCc-HY1tCOKYfPsZEDe90PhD4Cha_H63vdJtrrg=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Swagath Saligrama",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJLqGIa9rmpB_8nK1oCfjDu_0IGjyKCr5YRfjBZ3KctrFuOcA=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Deepak Kharvi",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocK79xI3sYPXJjyzrZ0o6Y1OeQpgN-RD0NfGfpsUPFbBHfyeJw=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "meghana Acharya",
        "rating": "4 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocIrc6q6r09rEjDl7KBNh1o0Dh4G73ptiI5ZaWqkgd5agRHdBW8N=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "prakash shanbhag",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocK6XhCbwiO2SuGkqn9odcYU_DFxa1NxO1nSIupLixWbxaw0Tw=w36-h36-p-rp-mo-ba2-br100"
    },
    {
        "name": "Nagaprasad acharya saligrama",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjU8zDeAXi2edDOp0Ij3yXJ1ZD6T2fhlYwRLNiWaFmlg14MpRfP3=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Mohith R Poojary",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXzKEcXbxlcT2aHzqNH3dY7dLL4ppjKM3koS7BZebgoESm8AtKi=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "Trupthi R Achar",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWoUS4Xf_Xq2_pKMBNblR2JlENTL6K_TqdpX3Hs69QY57CHXP3P=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "Deekshith",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXmGF7D90il4p_6pDc-631mYmzP8KUUeRbYuYGcj3xH4UWXFHD7=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "franky santhosh dmello",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjU9KwCOqf7-V3FWSbL5C8IOBcnm77Evboh1uBZarDZyerzaLp9g2g=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "MANOJ AIT20BEIS092",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjXgjzisyQFX7KGLJtxKDLULRTgJibRObYKW6oV8nr8kyqt8o-o=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "S Walker' WALKER",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjX1VZhdQstRwY1Pw98EZg6Nu9HpZq_PvukSxh0ahzNo9UHb8hxw=w36-h36-p-rp-mo-br100"
    },
    {
        "name": "roshan shetty",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a-/ALV-UjWIW0dJL3PNMLXDoW6J7xE_lZWcoLZ3IvZB_f9vM6gSwYeoJMM=w36-h36-p-rp-mo-ba3-br100"
    },
    {
        "name": "prasad kumar",
        "rating": "5 stars",
        "review": "-",
        "time": "3 years ago",
        "profile_picture": "https://lh3.googleusercontent.com/a/ACg8ocJjYsHMlCsyTuleisQ6aaVNT2860ufNRcOB0z3-DuPfM7G3WQ=w36-h36-p-rp-mo-br100"
    }

];



const getStars = (rating) => {
    const starsCount = parseInt(rating.split(" ")[0], 10);
    return "★".repeat(starsCount) + "☆".repeat(5 - starsCount);
  };
  
  function ReviewsCarousel() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [style, api] = useSpring(() => ({
      x: 0,
      config: { tension: 200, friction: 30 },
    }));
  
    useEffect(() => {
      const interval = setInterval(() => {
        handleNext();
      }, 3000);
  
      return () => clearInterval(interval); // Cleanup on unmount
    }, [currentIndex]);
  
    const handleNext = () => {
      if (currentIndex < reviewsData.length - 1) {
        setCurrentIndex((prev) => prev + 1);
        api.start({ x: -(currentIndex + 1) * 100 });
      } else {
        setCurrentIndex(0); // Loop back to the first review
        api.start({ x: 0 });
      }
    };
  
    const handlePrevious = () => {
      if (currentIndex > 0) {
        setCurrentIndex((prev) => prev - 1);
        api.start({ x: -(currentIndex - 1) * 100 });
      }
    };
  
    return (
      <div className="reviews-carousel-container">
        <h2 className="reviews-heading">
          Google Rating
          <span className="average-rating">
            5.0 ★★★★★ | {reviewsData.length+8} reviews
          </span>
        </h2>
        <div className="reviews-carousel">
          <button className="prev-btn" onClick={handlePrevious} disabled={currentIndex === 0}>
            &#8249;
          </button>
          <div className="carousel-wrapper" style={{ overflow: "hidden", width: "100%" }}>
            <animated.div
              style={{
                display: "flex",
                transform: style.x.to((x) => `translateX(${x}%)`),
                transition: "transform 0.5s ease",
              }}
              className="review-cards-wrapper"
            >
              {reviewsData.map((review, index) => (
                <div
                  className="review-card"
                  key={index}
                  style={{
                    flex: "0 0 100%",
                    maxWidth: "100%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={review.profile_picture}
                    alt={review.name}
                    style={{ width: "60px", height: "60px", borderRadius: "50%" }}
                  />
                  <h3>{review.name}</h3>
                  <p className="review-text">{review.review}</p>
                  <p className="review-time">{review.time}</p>
                  <p className="review-rating">{getStars(review.rating)}</p>
                </div>
              ))}
            </animated.div>
          </div>
          <button className="next-btn" onClick={handleNext} disabled={currentIndex === reviewsData.length - 1}>
            &#8250;
          </button>
        </div>
      </div>
    );
  }
  
  export default ReviewsCarousel;